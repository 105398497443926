import {useEffect, useState} from "react";
import {useDispatch, useSelector, useStore} from "react-redux";
import session from "redux-persist/lib/storage/session";
import UpApi from "up-api";
import {
  application,
  applicationPricing,
  applicationSession,
  courses,
  enrolments,
  files,
  invoicePricing,
  metadata,
  payments,
  pricing,
  profile,
  studentImage,
  updateApplication,
  updateUpSessionId
} from "./upApiReducer";

/**
 * Helper for api data fetching hooks
 * @param {*} action async action
 * @param {*} key storage key
 * @param {*} options
 * @param {*} params to pass to action when executed
 * @returns standard async action result, with an additional refresh function member so users can trigger a manual refresh (say from a button) . This will be non-null when params are valid
 *  { data, error, pending, refresh }
 */
function useUpApi(
  action,
  key,
  {
    force, // set true to always request data *once* when preconditions met (otherwise it only requests if data not already present)
    // Function to call to ensure all the needed paremeters are present (will need override for optionals etc)
    validateParams = (params) => Object.values(params).every((value) => !!value) // by default function just ensures all params non-null
  } = {},
  params
) {
  const selector = (state) => state[key];
  const current = useSelector(selector);
  const {pending, error, data} = current || {};
  const dispatch = useDispatch();
  const store = useStore();
  const [refresh, setRefresh] = useState(null);
  useEffect(() => {
    const {pending: latestPending, error: latestError} =
      selector(store.getState()) || {}; // note we re select the state here for latest changes in effect
    // If our preconditions are met we'll have a refresh function to call
    if (
      !!refresh &&
      (force || data === undefined) &&
      !(latestPending || latestError)
    ) {
      refresh(); // call it if we have no data or are being forced to
    }
  }, [force, refresh]); // note not dependent on data change as this would loop
  useEffect(() => {
    // as this is a hook and we need to preserve call order, we use function to check parameters to see if we can continue
    if (!refresh && (!params || validateParams(params)))
      setRefresh(() => () => dispatch(action(params))); // note nested nested func coz the set state handles functions specially
  }, [validateParams, params, dispatch]);
  return {refresh, pending, error, data};
}

/**
 * API data fetching hooks - save boilerplate/promise/state juggling with dispatches in useEffect
 */

/**
 *
 * @param {*} param0
 * @returns
 */

/**
 * Use the current application session from sessionStorage
 * @param {sessionId}
 * @returns
 */
export const useApplicationSession = ({sessionId}) => {
  return useUpApi(applicationSession, "applicationSession", {}, {sessionId});
};

/**
 * Use a current application data, fetching via session id if needed
 * @param {*} params
 * @param params.sessionId null to create new session, undefined to inherit from any in session storage or given id
 * @param params.update initialise the state application with the given body using PATCH/merge
 * @returns
 */
export const useApplication = ({sessionId: sessionIdParam, update} = {}) => {
  const sessionId = sessionIdParam || sessionStorage.getItem("up.sessionId");
  const {
    pending,
    error,
    data: sessionData
  } = useApplicationSession({sessionId});
  const app = useUpApi(
    update ? updateApplication : application,
    "application",
    {force: !!update}, // always update if we've been given some data
    {
      opportunityId: sessionData && sessionData.opportunityId,
      ...(update && {body: update})
    }
  );
  useEffect(() => {
    if (sessionData) {
      UpApi.configure({accessToken: sessionData.token});
    }
  }, [sessionData]);
  return {
    error: error || app.error,
    pending: pending || app.pending,
    data: sessionId ? app.data : {}
  };
};

export const useEnrolments = (studentId, options) =>
  useUpApi(enrolments, "enrolments", options, {studentId});
export const useFiles = (studentId, options) =>
  useUpApi(files, "files", {...options}, {studentId});
export const useMetadata = (options) =>
  useUpApi(metadata, "metadata", {...options});
export const useCourses = (provider, options) =>
  useUpApi(
    courses,
    "courses",
    {...options, validateParams: () => true},
    {provider}
  ); // uses provider from context if not supplied, so let it go ahead
export const usePayments = (studentId, options) =>
  useUpApi(payments, "payments", options, {studentId});
export const useProfile = (studentId, options) =>
  useUpApi(profile, "profile", options, {studentId});
export const useStudentImage = (studentId, options) =>
  useUpApi(studentImage, "studentImage", options, {studentId});

export const useProductPricing = ({productId, provider, ...options} = {}) =>
  useUpApi(pricing, "pricing", {...options}, {productId, provider});

/**
 * Invoice pricing is dependent on a current application (opportunity) being present, so use/request or force use of given invoice id
 * @returns
 */
export const useInvoicePricing = ({invoiceId: givenInvoice} = {}) => {
  const {
    data: {payment: {invoiceId} = {}} = {},
    pending,
    error
  } = useApplication();
  const result = useUpApi(
    invoicePricing,
    "invoicePricing",
    {force: !!givenInvoice},
    {invoiceId: givenInvoice || invoiceId}
  );
  return {pending, error, ...result};
};

/**
 * Application pricing is dependent on a current application (opportunity) being present, so use/request or force use of given opportunity
 * @returns
 */
export const useApplicationPricing = ({
  opportunityId: givenOppportunity,
  provider
} = {}) => {
  const {
    data: {opportunity: {opportunityCRMId} = {}} = {},
    pending,
    error
  } = useApplication();
  const result = useUpApi(
    applicationPricing,

    "applicationPricing",

    {force: !!givenOppportunity},

    {opportunityId: opportunityCRMId || givenOppportunity, provider}
  );
  return {pending, error, ...result};
};
